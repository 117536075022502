import React, {useContext, useEffect, useState} from "react";
import {Button, Card, Container, ListGroup, Spinner} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {AssignmentListDisplay, ResponseWrapper, Subject} from "../../util/Types";
import {StudentNavbar} from "../../navigation/StudentNavbar";
import {getAuthenticated} from "../../util/Requests";
import {assignmentListURL, noError} from "../../util/Globals";
import {UserContext} from "../../login/UserContext";
import {AssignmentList} from "./components/AssignmentList";
import {ErrorAlert} from "../../components/ErrorAlert";
import {IconButton} from "../../components/IconButton";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useHistory} from "react-router-dom";

interface Props {
    className: string,
    subject: Subject
}

export const SubjectPageStudent: React.FC<Props> = (props: Props): JSX.Element => {
    const userContext = useContext(UserContext);

    const history = useHistory();

    const [assignments, setAssignments] = useState<AssignmentListDisplay[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);

    const loadAssignments = () => {
        setLoading(true);
        setError(noError);

        const url = assignmentListURL + '/' + props.className + '/' + props.subject.code;

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setAssignments(wrapper.result);
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(console.log)
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    useEffect(loadAssignments, []);

    return (
        <Container>
            <StudentNavbar/>
            <VerticalPlaceholder height="1.5em"/>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <IconButton onClick={() => history.push('/class/' + encodeURIComponent(props.className))} variant="outline-dark" icon={faArrowLeft} className="float-left">Zurück</IconButton>
                        <span className="mr-1"/><span className="mr-2" style={{backgroundColor: props.subject.color}}>&nbsp;&nbsp;</span> {props.subject.name}
                    </Card.Title>

                </Card.Header>
                <Card.Body className="text-left">
                    <VerticalPlaceholder height="1em"/>
                    <AssignmentList useSelect={false} assignments={assignments} loading={loading}/>
                </Card.Body>
                <Card.Footer>
                    <ErrorAlert error={error}/>
                </Card.Footer>
            </Card>
        </Container>
    );
};
