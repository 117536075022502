import React, {FormEvent, useContext, useEffect, useRef, useState} from "react";
import {Card, Container, Form, FormControl, InputGroup} from "react-bootstrap";
import {VerticalPlaceholder} from "../../components/VerticalPlaceholder";
import {AssignmentListDisplay, ResponseWrapper, Subject} from "../../util/Types";
import {TeacherNavbar} from "../../navigation/TeacherNavbar";
import {UserContext} from "../../login/UserContext";
import {useHistory} from "react-router-dom";
import {assignmentListURL, assignmentURL, noError} from "../../util/Globals";
import {getAuthenticated, postBodyAuthenticated} from "../../util/Requests";
import {AssignmentList} from "./components/AssignmentList";
import {ErrorAlert} from "../../components/ErrorAlert";
import {IconButton} from "../../components/IconButton";
import {faArrowLeft, faPlus} from "@fortawesome/free-solid-svg-icons";

interface Props {
    className: string,
    subject: Subject
}

export const SubjectPageTeacher: React.FC<Props> = (props: Props): JSX.Element => {
    const userContext = useContext(UserContext);

    const history = useHistory();

    const [assignments, setAssignments] = useState<AssignmentListDisplay[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);

    const assignmentNameInputRef = useRef<HTMLInputElement>(null);

    const loadAssignments = () => {
        setLoading(true);
        setError(noError);

        const url = assignmentListURL + '/' + props.className + '/' + props.subject.code;

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setAssignments(wrapper.result);
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(console.log)
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    const createAssignment = (event?: FormEvent) => {
        if (event) {
            event.preventDefault();
        }

        if (!assignmentNameInputRef || !assignmentNameInputRef.current) {
            console.log('Invalid assignment name ref');
            return;
        }

        setLoading(true);
        setError(noError);

        postBodyAuthenticated(assignmentURL, userContext.user.token, {
            // @ts-ignore
            title: assignmentNameInputRef.current.value,
            subject: props.subject.code,
            className: props.className
        })
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadAssignments();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                console.log(reason);
                setError('Server konnte nicht erreicht werden');
                setLoading(false);
            });

        assignmentNameInputRef.current!.value = '';
    };

    useEffect(loadAssignments, []);

    return (
        <Container>
            <TeacherNavbar/>
            <VerticalPlaceholder height="1.5em"/>
            <Card>
                <Card.Header>
                    <Card.Title>
                        <IconButton onClick={() => history.push('/class/' + encodeURIComponent(props.className))} variant="outline-dark" icon={faArrowLeft} className="float-left">Zurück</IconButton>
                        <span className="mr-1"/><span className="mr-2" style={{backgroundColor: props.subject.color}}>&nbsp;&nbsp;</span> {props.subject.name}
                    </Card.Title>
                </Card.Header>
                <Card.Body className="text-left">

                    <VerticalPlaceholder height="1em"/>

                    <Form onSubmit={createAssignment}>
                        <InputGroup>
                            <FormControl ref={assignmentNameInputRef} placeholder="Titel für eine neue Aufgabe"/>
                            <InputGroup.Append>
                                <IconButton onClick={createAssignment} variant="success" loading={loading} icon={faPlus}/>
                            </InputGroup.Append>
                        </InputGroup>
                    </Form>

                    <VerticalPlaceholder height="1em"/>

                    <AssignmentList useSelect={true} refresh={loadAssignments} assignments={assignments} loading={loading}/>
                </Card.Body>
                <Card.Footer>
                    <ErrorAlert error={error}/>
                </Card.Footer>
            </Card>
        </Container>
    );
};
