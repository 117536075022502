import React, {useContext, useEffect, useState} from "react";
import {hasRole} from "../../../util/Roles";
import {RoleContext} from "../../../login/RoleContext";
import {classTextURL, noError, teacherRole} from "../../../util/Globals";
import {Card} from "react-bootstrap";
import {QuillEditor} from "../../../components/QuillEditor";
import {faEdit, faSave, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";
import {IconButton} from "../../../components/IconButton";
import {getAuthenticated, postBodyAuthenticated} from "../../../util/Requests";
import {UserContext} from "../../../login/UserContext";
import {ClassTextSummary, ResponseWrapper} from "../../../util/Types";
import {ErrorAlert} from "../../../components/ErrorAlert";

interface Props {
    className: string
}

export const ClassMessage: React.FC<Props> = (props: Props): JSX.Element => {
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);
    const [lastEditor, setLastEditor] = useState('');
    const [lastEditTimestamp, setLastEditTimestamp] = useState('');

    const [value, setValue] = useState('');
    const [lastPersistedValue, setLastPersistedValue] = useState('');

    const roleContext = useContext(RoleContext);
    const userContext = useContext(UserContext);

    const getSanitizedHTML = () => {
        // return DOMPurify.sanitize(value) => removed iFrames etc.
        return value;
    };

    const discardChanges = () => {
        setValue(lastPersistedValue);
        setEditing(false);
    };

    const loadClassText = () => {
        console.log('Requesting class text for', props.className);

        setLoading(true);
        setError(noError);

        const url = encodeURI(classTextURL + '?class=' + props.className);

        getAuthenticated(url, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;
                console.log('Class text response');
                console.log(wrapper);

                if (wrapper.error === noError) {
                    const classTextSummary = wrapper.result as ClassTextSummary;

                    const lastEditDate = new Date(classTextSummary.updated);
                    const timestamp = lastEditDate.getDate() + '.' + (lastEditDate.getMonth() + 1) + '.' + lastEditDate.getFullYear();

                    setLastEditTimestamp(timestamp);

                    setValue(classTextSummary.text);
                    setLastPersistedValue(classTextSummary.text);
                    setLastEditor(classTextSummary.lastEditor);
                } else {
                    setError(wrapper.error);
                }
            })
            // Finally is OK here
            .finally(() => setLoading(false));
    };

    const persistChanges = () => {
        setError(noError);
        setLoading(true);
        setLastPersistedValue(value);

        postBodyAuthenticated(classTextURL, userContext.user.token, {className: props.className, text: value})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    // Don't do anything for now
                } else {
                    setError(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            })
            // Finally is OK here
            .finally(() => {
                setLoading(false);
                setEditing(false);
            });
    };

    useEffect(loadClassText, [props.className]);

    if (hasRole(roleContext.roles, teacherRole)) {
        if (editing) {
            return (
                <Card>
                    <Card.Header>

                    </Card.Header>
                    <Card.Body>
                        <QuillEditor text={value} valueChangeHandler={setValue}/>

                        <VerticalPlaceholder height="0.7em"/>

                        <IconButton loading={loading} icon={faSave} size="sm" variant='success' className="mr-4 float-right" onClick={persistChanges}>Speichern</IconButton>
                        <IconButton loading={loading} icon={faTrashAlt} size="sm" variant='danger' className="mr-4 float-right" onClick={discardChanges}>Verwerfen</IconButton>
                    </Card.Body>
                    <Card.Footer>
                        <Card.Text><small>Zuletzt geändert am {lastEditTimestamp} von {lastEditor}</small></Card.Text>
                    </Card.Footer>
                    <ErrorAlert error={error}/>
                </Card>
            );
        } else {
            return (
                <Card>
                    <Card.Header>

                    </Card.Header>
                    <Card.Body>
                        <div className="ignoreParents">
                            { <div className="arial" dangerouslySetInnerHTML={{ __html: getSanitizedHTML() }} /> }
                        </div>

                        <VerticalPlaceholder height="0.7em"/>

                        <IconButton loading={loading} icon={faEdit} size="sm" variant='primary' className="mr-4 float-right" onClick={() => setEditing(true)}>Bearbeiten</IconButton>
                    </Card.Body>
                    <Card.Footer>
                        <Card.Text><small>Zuletzt geändert am {lastEditTimestamp} von {lastEditor}</small></Card.Text>
                    </Card.Footer>
                    <ErrorAlert error={error}/>
                </Card>
            );
        }
    } else {
        return (
            <Card>
                <Card.Header>

                </Card.Header>
                <Card.Body>
                    <div className="ignoreParents">
                        { <div className="arial" dangerouslySetInnerHTML={{ __html: getSanitizedHTML() }} /> }
                    </div>
                </Card.Body>
                <Card.Footer>
                    <Card.Text><small>Zuletzt geändert am {lastEditTimestamp} von {lastEditor}</small></Card.Text>
                </Card.Footer>
                <ErrorAlert error={error}/>
            </Card>
        );
    }
};
