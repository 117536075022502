import React, {useContext, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import {getFileIcon} from "../../../util/icons";
import {getAuthenticated, postFormAuthenticated} from "../../../util/Requests";
import {UserContext} from "../../../login/UserContext";
import {filesURL, noError, scheduleExistsURL, scheduleURL} from "../../../util/Globals";
import {ResponseWrapper} from "../../../util/Types";
import {MainContentSpinner} from "../../../components/MainContentSpinner";
import {CustomFileInputSchedule} from "../../../components/CustomFileInputSchedule";
import {IconButton} from "../../../components/IconButton";
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";
import {scheduleName} from "../../../config/preferences";

interface Props {
    className: string
}

export const ScheduleTeacher: React.FC<Props> = (props: Props): JSX.Element => {
    const [scheduleExists, setScheduleExists] = useState(false);
    const [editing, setEditing] = useState(false);
    const [error, setError] = useState<string|string[]>(noError);
    const [loading, setLoading] = useState(false);

    const userContext = useContext(UserContext);

    const postSchedule = (files: File[]) => {
        if (files.length === 0) {
            setError('Keine Datei gefunden');
            return;
        }

        setLoading(true);
        setError(noError);
        postFormAuthenticated(scheduleURL + '/' + props.className, userContext.user.token, {file: files[0]})
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    loadScheduleExists();
                } else {
                    setError(wrapper.error);
                    setLoading(false);
                }
            })
            .catch(reason => {
                setError('Server konnte nicht erreicht werden');
                setLoading(false);
            })
            .finally(() => setEditing(false));
    };

    const loadScheduleExists = () => {
        setLoading(true);
        setError(noError);
        getAuthenticated(scheduleExistsURL + '/' + props.className, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setScheduleExists(wrapper.result === true);
                } else {
                    setError(wrapper.error);
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                setError('Server konnte nicht erreicht werden');
                console.log(reason);
            })
            .finally(() => setLoading(false));
    };

    const openSchedule = () => {
        window.open(filesURL + '/schedule/' + props.className);
    };

    useEffect(loadScheduleExists, []);

    if (loading) {
        return <div>
            <MainContentSpinner/>
            <VerticalPlaceholder height="1.5em"/>
        </div>;
    }

    if (!scheduleExists) {
        return <div>
            <CustomFileInputSchedule handleFileTransfer={postSchedule} multiple={false}/>
        </div>;
    }

    if (editing) {
        return <div>
            <div className="scheduleUploadLabel">
                <CustomFileInputSchedule handleFileTransfer={postSchedule} multiple={false}/>
                <IconButton variant="danger" onClick={() => setEditing(false)} className="float-right" icon={faTimes} size="sm">Abbrechen</IconButton>
            </div>
            <VerticalPlaceholder height="2.5em"/>
        </div>;
    }

    return (
        <div style={{width: '90%', margin: 'auto'}}>
            <a className="text-decoration-none" href={filesURL + '/schedule/' + props.className} target="_blank">
                <Alert variant="dark" className="scheduleLink">
                    <span className={'mr-3 ' + getFileIcon(props.className + '.pdf')}/>
                    {scheduleName}
                </Alert>
            </a>
            <VerticalPlaceholder height="0.8em"/>
            <IconButton onClick={() => setEditing(true)} className="float-right" icon={faEdit} size="sm">{scheduleName} ersetzen</IconButton>
            <VerticalPlaceholder height="2.5em"/>
        </div>
    );
};
