import React, {useContext, useEffect, useState} from "react";
import {Alert} from "react-bootstrap";
import {getFileIcon} from "../../../util/icons";
import {getAuthenticated} from "../../../util/Requests";
import {UserContext} from "../../../login/UserContext";
import {filesURL, noError, scheduleExistsURL} from "../../../util/Globals";
import {ResponseWrapper} from "../../../util/Types";
import {VerticalPlaceholder} from "../../../components/VerticalPlaceholder";

interface Props {
    className: string
}

export const ScheduleStudent: React.FC<Props> = (props: Props): JSX.Element => {
    const [scheduleExists, setScheduleExists] = useState(false);

    const userContext = useContext(UserContext);

    const loadScheduleExists = () => {
        getAuthenticated(scheduleExistsURL + '/' + props.className, userContext.user.token)
            .then(response => {
                const wrapper = response as ResponseWrapper;

                if (wrapper.error === noError) {
                    setScheduleExists(wrapper.result === true);
                } else {
                    console.log(wrapper.error);
                }
            })
            .catch(reason => {
                console.log(reason);
            });
    };

    const openSchedule = () => {
        window.open(filesURL + '/schedule/' + props.className);
    };

    useEffect(loadScheduleExists, []);

    if (!scheduleExists) {
        return <span/>;
    }

    return (
        <div>
            <a className="text-decoration-none" href={filesURL + '/schedule/' + props.className} target="_blank">
                <Alert variant="dark" className="scheduleLink">
                    <span className={'mr-3 ' + getFileIcon(props.className + '.pdf')}/>
                    Arbeitsplan
                </Alert>
            </a>
            <VerticalPlaceholder height="1.5em"/>
        </div>
    );
};
